import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Permission } from '../enums/generated.enums';
import { ModuleType, PageType, SubPageType } from '../enums/page-type.enum';
import { AuthService } from '../services/auth.service';
import { NavigationService } from '../services/navigation.service';

@Injectable({ providedIn: 'root' })
export class PermissionAuthGuard  {
  constructor(private authService: AuthService, private navigationService: NavigationService) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    this.navigationService.setCurrentRoute(route);

    const module = this.navigationService.currentModule;
    const page = this.navigationService.currentPage;
    const subPage = this.navigationService.currentSubPage;

    const permission = this.getPermissions(module, page, subPage);
    if (Array.isArray(permission)) {
      for (const perm of permission) {
        if (!this.authService.hasPermission(perm)) {
          await this.navigationService.navigateToDefaultPage();
          return false;
        }
      }
    } else if (!this.authService.hasPermission(permission)) {
      await this.navigationService.navigateToDefaultPage();
      return false;
    }

    return true;
  }

  private getPermissions(module: ModuleType, page: PageType, subPage?: SubPageType): Permission | Permission[] {
    switch (module) {
      case ModuleType.Monitoring:
        return Permission.Monitoring;
      case ModuleType.Orchestration:
        return page === PageType.Simulator ? Permission.Simulator : Permission.Orchestration;
      case ModuleType.Analytics:
        return page === PageType.RuntimeAnalysis ? Permission.RuntimeAnalysis : Permission.Analytics;
      case ModuleType.Reporting:
        switch (page) {
          case PageType.ProcessReporting:
            return Permission.ProcessReporting;
          case PageType.ExecutiveOverview:
            return Permission.Reporting;
          case PageType.BenchmarkOverview:
            return Permission.Benchmarking;
          default:
            return Permission.Reporting;
        }
      case ModuleType.Settings:
        switch (subPage) {
          case SubPageType.OrchestrationSettings:
            return [Permission.OrganizationSettings, Permission.Orchestration];
          case SubPageType.PendingSessions:
            return [Permission.OrganizationSettings, Permission.Orchestration];
          case SubPageType.Simulator:
            return [Permission.OrganizationSettings, Permission.Simulator];
          case SubPageType.BillingGroupsSettings:
            return [Permission.OrganizationSettings, Permission.Reporting];
          case SubPageType.BenchmarkingSettings:
            return [Permission.OrganizationSettings, Permission.Benchmarking];
          default:
            return Permission.OrganizationSettings;
        }
      case ModuleType.Admin:
        return Permission.SystemAdmin;
      default:
        return null;
    }
  }
}
